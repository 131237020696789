@import url("highlight.js/styles/github.css");
@import url("highlight.js/styles/github-dark.css") (prefers-color-scheme: dark);
@font-face {
  font-family: "Ubuntu Mono";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Ubuntu Mono"), local("UbuntuMono"), local("Ubuntu Mono Regular"), url("../fonts/ubuntu-mono-latin-400.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Ubuntu Mono";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/ubuntu-mono-latin-ext-400.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Ubuntu Mono";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/ubuntu-mono-cyryllic-400.woff2") format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/rubik/rubik-cyrillic-400.woff2) format("woff2"), url(../fonts/rubik/rubik-400.woff) format("woff");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/rubik/rubik-cyrillic-400-italic.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/rubik/rubik-cyrillic-ext-400.woff2) format("woff2"), url(../fonts/rubik/rubik-400.woff) format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/rubik/rubik-cyrillic-ext-400-italic.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/rubik/rubik-latin-400.woff2) format("woff2"), url(../fonts/rubik/rubik-400.woff) format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/rubik/rubik-latin-400-italic.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/rubik/rubik-latin-ext-400.woff2) format("woff2") url(../fonts/rubik/rubik-400.woff) format("woff");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/rubik/rubik-latin-ext-400-italic.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/rubik/rubik-cyrillic-500.woff2) format("woff2"), url(../fonts/rubik/rubik-500.woff) format("woff");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/rubik/rubik-cyrillic-500-italic.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/rubik/rubik-cyrillic-ext-500.woff2) format("woff2"), url(../fonts/rubik/rubik-500.woff) format("woff");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/rubik/rubik-cyrillic-ext-500-italic.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/rubik/rubik-latin-500.woff2) format("woff2"), url(../fonts/rubik/rubik-500.woff) format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/rubik/rubik-latin-500-italic.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/rubik/rubik-latin-ext-500.woff2) format("woff2"), url(../fonts/rubik/rubik-500.woff) format("woff");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Rubik";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/rubik/rubik-latin-ext-500-italic.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
:root {
  --m3-container-secondary: #dae2ff;
  --m3-on-container-secondary: #2d4b77;
  --m3-container-primary: #3a5ba9;
  --m3-on-container-primary: #ffffff;
  --body-background: #f8f9fa;
  --body-text-color: #24292f;
  --body-light-muted-color: rgba(155, 162, 176, 0.14);
  --body-muted-text-color: #868686;
  --header-background: #ffffff;
  --header-box-shadow-color: rgba(151, 164, 175, .1);
  --header-logo-color: #303757;
  --header-border-bottom-color: transparent;
  --card-background: #ffffff;
  --card-border-color: #e7eaf3;
  --card-box-shadow-color: rgba(189, 197, 209, .2);
  --card-header-background: var(--card-background);
  --card-header-color: #707579;
  --card-header-tab-color: #7c8286;
  --card-row-separator: #eef0f6;
  --card-pill-background: #efefef;
  --blue-bright: #0069ff;
  --blue-bright-background: rgba(56, 139, 253, 0.1);
  --cyan-bright: #3fa5bd;
  --green-bright: #02977e;
  --red-bright: #f74b4c;
  --yellow-bright: #f9c426;
  --orange-bright: #fc9037;
  --badge-red-color: #ec6471;
  --badge-red-background-color: rgba(155, 82, 89, 0.18);
  --badge-green-color: var(--green-bright);
  --badge-green-background-color: rgba(0, 201, 167, 0.2);
  --badge-grey-color: #7f9ab9;
  --badge-grey-background-color: #ecf0f5;
  --modal-title-background: var(--body-background);
  --modal-border-color: transparent;
  --modal-banner-background: var(--m3-container-secondary);
  --modal-banner-text-color: var(--m3-on-container-secondary);
  --modal-banner-round-background: var(--m3-container-primary);
  --modal-banner-round-icon-fill: var(--m3-on-container-primary);
  --tx-table-thead-alt-background: var(--body-background);
  --tx-table-details-background-color: rgba(238, 238, 238, 0.2);
  --tx-table-icon-color: #656565;
  --tx-table-icon-error-color: #ec6471;
  --tx-table-icon-error-hover-color: #da1b2d;
  --tx-table-icon-error-background: #fdeeec;
  --tx-messages-message-background: var(--tx-table-details-background-color);
  --indexpage-search-background-color: #ffffff;
  --ambilight-overlay-color: rgba(255, 255, 255, 0.6);
  --nft-preview-image-outline: rgba(0, 0, 0, 0.06);
  --nft-preview-error-card-background: #ffffff;
  --nft-preview-background: var(--body-light-muted-color);
  --nft-preview-hover-background: #dddddd;
  --nft-preview-hover-color: #0e0e0e;
  --big-blue-button-background: #1f7bff;
  --big-blue-button-hover-background: #0067fa;
  --big-blue-button-disabled-background: #f1f2f4;
  --big-blue-button-disabled-color: #8B8888;
  --stacked-bar-background: #edebeb;
  --stacked-bar-meh: #c6c0c0;
  --stacked-bar-red: #D96A65;
  --stacked-bar-green: #599f5e;
  --ui-round-image-color: rgba(155, 162, 176, 0.28);
  --code-viewer-background: var(--card-background);
  --code-viewer-border-color: #dee1ed;
  --code-viewer-tab-inactive-background: #f7f8f9;
  --code-viewer-file-selector-background-color: #f8f9fa;
  --code-viewer-file-selector-border-color: var(--code-viewer-border-color);
  --user-nft-list-item-background: #fafafa;
  --user-nft-list-item-hover-background: #ffffff;
  --user-nft-list-item-box-shadow-blur: 20px;
  --user-nft-list-item-text-muted-color: #828282;
  --page-tx-status-success-color: var(--green-bright);
  --page-tx-status-error-color: #e64040;
  --page-tx-flow-diagram-border-color: #dadcde;
  --theme-selector-light-background: var(--card-background);
  --theme-selector-light-border-color: var(--card-row-separator);
  --theme-selector-light-separator-color: var(--card-row-separator);
  --theme-selector-light-text-color: var(--body-text-color);
  --theme-selector-dark-background: #1c1b1b;
  --theme-selector-dark-border-color: #2e2e2e;
  --theme-selector-dark-separator-color: #393939;
  --theme-selector-dark-text-color: #FFF;
  --theme-selector-auto-background: var(--theme-selector-light-background);
  --theme-selector-auto-border-color: var(--theme-selector-light-border-color);
  --theme-selector-auto-separator-color: var(--theme-selector-light-separator-color);
  --theme-selector-auto-text-color: var(--theme-selector-light-text-color);
  --chart-grid-color: #F0F2F5;
  --chart-pie-border-color: var(--card-background);
  --chart-skeleton-background-color: var(--body-light-muted-color);
  --chart-line-green-color: var(--green-bright);
  --chart-line-red-color: #bd4747;
  --chart-interval-selector-item-background: #ffffff;
  --chart-interval-selector-item-active-color: #000;
  --chart-interval-selector-background: rgba(19,19,20,.04);
  --chart-interval-selector-color: #8a8a93;
  --chart-bar-primary-color: var(--chart-line-green-color);
  --chart-bar-secondary-color: #6c8e7550;
  --chart-tooltip-background: #ffffff;
  --chart-tooltip-color: var(--body-text-color);
  --table-header-background: rgba(19,19,20,.04);
  --table-header-color: #8a8a93;
  --table-row-border: 1px solid var(--card-row-separator);
  --skeleton-loader-background: rgba(0, 0, 0, 0.06);
  --app-short-description: #929497;
  --app-card-description: var(--app-short-description);
  --app-info-name: var(--app-short-description);
  --app-info-value: #0065F5;
  --app-icon-background: var(--body-background);
  --app-card-badge-new-background: #D5F1EC;
  --app-logo-shadow: 1px 1px rgba(0, 0, 0, 0.5);
  --app-search-icon: #888B8F;
  --app-search-field-focused-border-color: #2575ed;
  --app-search-badge-text-color: var(--app-short-description);
  --app-icon-verified-color: #0065F5;
  --selector-active: #ffffff;
  --selector-background: #EDEEF0;
  --selected-big-text: #8a8a92;
  --selector-big-selected-text: var(--body-text-color);
  --selector-big-selected-border: #0065F5;
  --selector-big-selected-background: transparent;
  --selector-big-mobile-border-color: #E7EAF3;
  --selector-small-background: #edeef0;
  --selector-small-text: #878b8f;
  --selector-small-selected-background: #ffffff;
  --selector-small-selected-text: var(--body-text-color);
  --app-list-nav-btn-open-background: #EDEEF0;
  --app-list-nav-btn-text: #878b8f;
  --apps-navigation-icon-active: #3D73E5;
  --apps-navigation-category-active-color: var(--body-text-color);
  --apps-navigation-inactive-color: #858585;
  --apps-navigation-hover-color: var(--body-text-color);
  --icon-color: #707477;
  --icon-hover-color: #000000;
  --apps-navigation-btn-background: var(--card-background);
  --apps-navigation-btn-border-color: var(--selector-big-mobile-border-color);
  --apps-navigation-btn-selected-background: #fff;
  --apps-navigation-btn-selected-text-color: rgba(0, 101, 245, 1);
  --apps-navigation-btn-selected-border-color: rgba(0, 101, 245, 1);
  --apps-navigation-btn-active-background-color: rgba(255,255,255, 0.9);
  --breadcrumbs-text-color: #878b8f;
  --breadcrumbs-selected-text-color: var(--body-text-color);
  --config-param-number-text-color: var(--body-text-color);
  --config-param-number-border-color: var(--body-text-color);
  --config-param-number-background-color: transparent;
  --config-param-point-background-color: #f4f6f8;
  --config-param-point-text-color: #5e5e5e;
  --config-param-no-value: #fcfcfc;
  --config-param-number-text-color: var(--body-text-color);
  --config-param-number-border-color: var(--body-text-color);
  --config-param-number-background-color: transparent;
  --config-param-point-background-color: #f4f6f8;
  --config-param-point-text-color: #5e5e5e;
  --config-param-no-value: #fcfcfc;
}

:root[data-theme=dark] {
  --m3-container-secondary: #1e438f;
  --m3-on-container-secondary: #dae2ff;
  --body-background: #0f0f0f;
  --body-text-color: #cfd5dd;
  --body-light-muted-color: rgba(91, 91, 91, 0.2);
  --body-muted-text-color: #7e7e7e;
  --header-background: #212121;
  --header-logo-color: #eeeeee;
  --header-box-shadow-color: #1010109c;
  --header-border-bottom-color: var(--card-border-color);
  --card-background: #212121;
  --card-border-color: #2f2f2f;
  --card-box-shadow-color: #1010109c;
  --card-header-background: var(--card-background);
  --card-header-color: #bfbfbf;
  --card-header-tab-color: #7c8286;
  --card-row-separator: #2e2e2e;
  --card-pill-background: #333;
  --blue-bright: #4d96ff;
  --blue-bright-background: rgba(77, 152, 254, 0.1);
  --green-bright: #3fb950;
  --red-bright: #f74b4c;
  --cyan-bright: #80d6ea;
  --yellow-bright: #fdd764;
  --orange-bright: #fda964;
  --badge-red-color: #f87777;
  --badge-red-background-color: rgba(230, 79, 79, 0.18);
  --badge-green-color: #2bb259;
  --badge-green-background-color: rgba(30, 176, 60, 0.18);
  --badge-grey-color: #777;
  --badge-grey-background-color: #2f2f2f;
  --modal-title-background: #1c1b1b;
  --modal-border-color: var(--card-border-color);
  --modal-banner-background: var(--m3-container-secondary);
  --modal-banner-text-color: var(--m3-on-container-secondary);
  --modal-banner-round-background: #3a5ba9;
  --modal-banner-round-icon-fill: #FFFFFF;
  --tx-table-thead-alt-background: #1c1b1b;
  --tx-table-details-background-color: #1a1a1a;
  --tx-table-icon-color: #5c5c5c;
  --tx-table-icon-error-color: #f87777;
  --tx-table-icon-error-hover-color: #faa8a8;
  --tx-table-icon-error-background: var(--badge-red-background-color);
  --tx-messages-message-background: #1c1b1b;
  --indexpage-search-background-color: #000000;
  --ambilight-overlay-color: #303030;
  --nft-preview-image-outline: rgba(255, 255, 255, 0.06);
  --nft-preview-error-card-background: radial-gradient(circle, transparent 30%, rgb(18,18,18) 100%);
  --nft-preview-background: var(--body-light-muted-color);
  --nft-preview-hover-background: #cfd5dd;
  --nft-preview-hover-color: #0e0e0e;
  --big-blue-button-background: #0065f5;
  --big-blue-button-hover-background: #0f72ff;
  --big-blue-button-disabled-background: #474747;
  --big-blue-button-disabled-color: #AAA;
  --stacked-bar-background: #303030;
  --stacked-bar-meh: #4f4f4f;
  --stacked-bar-red: linear-gradient(180deg, #A1615E 0%, #834C45 100%);
  --stacked-bar-green: linear-gradient(180deg, #5ea161 0%, #47874e 100%);
  --ui-round-image-color: rgba(91, 91, 91, 0.33);
  --code-viewer-background: #151515;
  --code-viewer-border-color: #2a2a2a;
  --code-viewer-tab-inactive-background: #1c1b1b;
  --code-viewer-file-selector-background-color: rgba(255, 255, 255, 0.06);
  --code-viewer-file-selector-border-color: #353535;
  --user-nft-list-item-background: #2a2a2a;
  --user-nft-list-item-hover-background: #313131;
  --user-nft-list-item-box-shadow-blur: 12px;
  --user-nft-list-item-text-muted-color: #999;
  --page-tx-status-success-color: var(--green-bright);
  --page-tx-status-error-color: #ff706f;
  --page-tx-flow-diagram-border-color: #444444;
  --theme-selector-light-background: #FAFAFA;
  --theme-selector-light-border-color: var(--card-background);
  --theme-selector-light-separator-color: #ddd;
  --theme-selector-light-text-color: #24292f;
  --theme-selector-dark-background: var(--card-row-separator);
  --theme-selector-dark-border-color: #404040;
  --theme-selector-dark-separator-color: #404040;
  --theme-selector-dark-text-color: var(--body-text-color);
  --theme-selector-auto-background: var(--theme-selector-dark-background);
  --theme-selector-auto-border-color: var(--theme-selector-dark-border-color);
  --theme-selector-auto-separator-color: var(--theme-selector-dark-separator-color);
  --theme-selector-auto-text-color: var(--theme-selector-dark-text-color);
  --chart-grid-color: #2a2a2a;
  --chart-pie-border-color: var(--card-background);
  --chart-skeleton-background-color: var(--body-light-muted-color);
  --chart-line-green-color: #6c8e75;
  --chart-line-red-color: #bd4747;
  --chart-interval-selector-item-background: var(--card-row-separator);
  --chart-interval-selector-item-active-color: var(--body-text-color);
  --chart-interval-selector-background: rgba(0, 0, 0, 0.4);
  --chart-interval-selector-color: #cfd5dd90;
  --chart-bar-primary-color: var(--chart-line-green-color);
  --chart-bar-secondary-color: #6c8e7550;
  --chart-tooltip-background: #2c2c2c;
  --chart-tooltip-color: #fff;
  --table-header-background: var(--card-row-separator);
  --table-header-color: #cfd5dd90;
  --table-row-border: 1px solid var(--card-row-separator);
  --skeleton-loader-background: rgba(255, 255, 255, 0.06);
  --app-short-description: #929497;
  --app-card-description: var(--app-short-description);
  --app-info-name: var(--app-short-description);
  --app-info-value: #0065F5;
  --app-icon-background: #2e2e2e;
  --app-card-badge-new-background: #2d453b;
  --app-logo-shadow: 1px 1px rgba(0, 0, 0, 0.5);
  --app-search-field-focused-border-color: #2575ed;
  --app-search-badge-text-color: #bbbfc4;
  --app-icon-verified-color: #4d96ff;
  --selector-active: #2f2f2f;
  --selector-background: var(--card-background);
  --selected-big-text: #909090;
  --selector-big-selected-text: var(--body-text-color);
  --selector-big-selected-border: var(--app-search-field-focused-border-color);
  --selector-big-selected-background: transparent;
  --selector-big-mobile-border-color: transparent;
  --selector-small-background: var(--card-background);
  --selector-small-text: #909090;
  --selector-small-selected-background: #333333;
  --selector-small-selected-text: var(--body-text-color);
  --app-list-nav-btn-open-background: var(--card-background);
  --app-list-nav-btn-text: #909090;
  --apps-navigation-icon-active: var(--blue-bright);
  --apps-navigation-category-active-color: var(--body-text-color);
  --apps-navigation-inactive-color: #858585;
  --apps-navigation-hover-color: var(--body-text-color);
  --icon-color: var(--body-text-color);
  --icon-hover-color: var(--body-text-color);
  --apps-navigation-btn-background: var(--card-background);
  --apps-navigation-btn-border-color: transparent;
  --apps-navigation-btn-selected-background: rgba(255,255,255, 0.1);
  --apps-navigation-btn-selected-text-color: var(--body-text-color);
  --apps-navigation-btn-selected-border-color: var(--app-search-field-focused-border-color);
  --apps-navigation-btn-active-background-color: rgba(255,255,255, 0.1);
  --breadcrumbs-text-color: #878b8f;
  --breadcrumbs-selected-text-color: var(--body-text-color);
  --config-param-number-text-color: var(--body-text-color);
  --config-param-number-border-color: #525252;
  --config-param-number-background-color: transparent;
  --config-param-point-background-color: #2d2d2d;
  --config-param-point-text-color: #5e5e5e;
  --config-param-no-value: var(--code-viewer-background);
}

@media (prefers-color-scheme: dark) {
  [data-theme=auto] {
    --m3-container-secondary: #1e438f;
    --m3-on-container-secondary: #dae2ff;
    --body-background: #0f0f0f;
    --body-text-color: #cfd5dd;
    --body-light-muted-color: rgba(91, 91, 91, 0.2);
    --body-muted-text-color: #7e7e7e;
    --header-background: #212121;
    --header-logo-color: #eeeeee;
    --header-box-shadow-color: #1010109c;
    --header-border-bottom-color: var(--card-border-color);
    --card-background: #212121;
    --card-border-color: #2f2f2f;
    --card-box-shadow-color: #1010109c;
    --card-header-background: var(--card-background);
    --card-header-color: #bfbfbf;
    --card-header-tab-color: #7c8286;
    --card-row-separator: #2e2e2e;
    --card-pill-background: #333;
    --blue-bright: #4d96ff;
    --blue-bright-background: rgba(77, 152, 254, 0.1);
    --green-bright: #3fb950;
    --red-bright: #f74b4c;
    --cyan-bright: #80d6ea;
    --yellow-bright: #fdd764;
    --orange-bright: #fda964;
    --badge-red-color: #f87777;
    --badge-red-background-color: rgba(230, 79, 79, 0.18);
    --badge-green-color: #2bb259;
    --badge-green-background-color: rgba(30, 176, 60, 0.18);
    --badge-grey-color: #777;
    --badge-grey-background-color: #2f2f2f;
    --modal-title-background: #1c1b1b;
    --modal-border-color: var(--card-border-color);
    --modal-banner-background: var(--m3-container-secondary);
    --modal-banner-text-color: var(--m3-on-container-secondary);
    --modal-banner-round-background: #3a5ba9;
    --modal-banner-round-icon-fill: #FFFFFF;
    --tx-table-thead-alt-background: #1c1b1b;
    --tx-table-details-background-color: #1a1a1a;
    --tx-table-icon-color: #5c5c5c;
    --tx-table-icon-error-color: #f87777;
    --tx-table-icon-error-hover-color: #faa8a8;
    --tx-table-icon-error-background: var(--badge-red-background-color);
    --tx-messages-message-background: #1c1b1b;
    --indexpage-search-background-color: #000000;
    --ambilight-overlay-color: #303030;
    --nft-preview-image-outline: rgba(255, 255, 255, 0.06);
    --nft-preview-error-card-background: radial-gradient(circle, transparent 30%, rgb(18,18,18) 100%);
    --nft-preview-background: var(--body-light-muted-color);
    --nft-preview-hover-background: #cfd5dd;
    --nft-preview-hover-color: #0e0e0e;
    --big-blue-button-background: #0065f5;
    --big-blue-button-hover-background: #0f72ff;
    --big-blue-button-disabled-background: #474747;
    --big-blue-button-disabled-color: #AAA;
    --stacked-bar-background: #303030;
    --stacked-bar-meh: #4f4f4f;
    --stacked-bar-red: linear-gradient(180deg, #A1615E 0%, #834C45 100%);
    --stacked-bar-green: linear-gradient(180deg, #5ea161 0%, #47874e 100%);
    --ui-round-image-color: rgba(91, 91, 91, 0.33);
    --code-viewer-background: #151515;
    --code-viewer-border-color: #2a2a2a;
    --code-viewer-tab-inactive-background: #1c1b1b;
    --code-viewer-file-selector-background-color: rgba(255, 255, 255, 0.06);
    --code-viewer-file-selector-border-color: #353535;
    --user-nft-list-item-background: #2a2a2a;
    --user-nft-list-item-hover-background: #313131;
    --user-nft-list-item-box-shadow-blur: 12px;
    --user-nft-list-item-text-muted-color: #999;
    --page-tx-status-success-color: var(--green-bright);
    --page-tx-status-error-color: #ff706f;
    --page-tx-flow-diagram-border-color: #444444;
    --theme-selector-light-background: #FAFAFA;
    --theme-selector-light-border-color: var(--card-background);
    --theme-selector-light-separator-color: #ddd;
    --theme-selector-light-text-color: #24292f;
    --theme-selector-dark-background: var(--card-row-separator);
    --theme-selector-dark-border-color: #404040;
    --theme-selector-dark-separator-color: #404040;
    --theme-selector-dark-text-color: var(--body-text-color);
    --theme-selector-auto-background: var(--theme-selector-dark-background);
    --theme-selector-auto-border-color: var(--theme-selector-dark-border-color);
    --theme-selector-auto-separator-color: var(--theme-selector-dark-separator-color);
    --theme-selector-auto-text-color: var(--theme-selector-dark-text-color);
    --chart-grid-color: #2a2a2a;
    --chart-pie-border-color: var(--card-background);
    --chart-skeleton-background-color: var(--body-light-muted-color);
    --chart-line-green-color: #6c8e75;
    --chart-line-red-color: #bd4747;
    --chart-interval-selector-item-background: var(--card-row-separator);
    --chart-interval-selector-item-active-color: var(--body-text-color);
    --chart-interval-selector-background: rgba(0, 0, 0, 0.4);
    --chart-interval-selector-color: #cfd5dd90;
    --chart-bar-primary-color: var(--chart-line-green-color);
    --chart-bar-secondary-color: #6c8e7550;
    --chart-tooltip-background: #2c2c2c;
    --chart-tooltip-color: #fff;
    --table-header-background: var(--card-row-separator);
    --table-header-color: #cfd5dd90;
    --table-row-border: 1px solid var(--card-row-separator);
    --skeleton-loader-background: rgba(255, 255, 255, 0.06);
    --app-short-description: #929497;
    --app-card-description: var(--app-short-description);
    --app-info-name: var(--app-short-description);
    --app-info-value: #0065F5;
    --app-icon-background: #2e2e2e;
    --app-card-badge-new-background: #2d453b;
    --app-logo-shadow: 1px 1px rgba(0, 0, 0, 0.5);
    --app-search-field-focused-border-color: #2575ed;
    --app-search-badge-text-color: #bbbfc4;
    --app-icon-verified-color: #4d96ff;
    --selector-active: #2f2f2f;
    --selector-background: var(--card-background);
    --selected-big-text: #909090;
    --selector-big-selected-text: var(--body-text-color);
    --selector-big-selected-border: var(--app-search-field-focused-border-color);
    --selector-big-selected-background: transparent;
    --selector-big-mobile-border-color: transparent;
    --selector-small-background: var(--card-background);
    --selector-small-text: #909090;
    --selector-small-selected-background: #333333;
    --selector-small-selected-text: var(--body-text-color);
    --app-list-nav-btn-open-background: var(--card-background);
    --app-list-nav-btn-text: #909090;
    --apps-navigation-icon-active: var(--blue-bright);
    --apps-navigation-category-active-color: var(--body-text-color);
    --apps-navigation-inactive-color: #858585;
    --apps-navigation-hover-color: var(--body-text-color);
    --icon-color: var(--body-text-color);
    --icon-hover-color: var(--body-text-color);
    --apps-navigation-btn-background: var(--card-background);
    --apps-navigation-btn-border-color: transparent;
    --apps-navigation-btn-selected-background: rgba(255,255,255, 0.1);
    --apps-navigation-btn-selected-text-color: var(--body-text-color);
    --apps-navigation-btn-selected-border-color: var(--app-search-field-focused-border-color);
    --apps-navigation-btn-active-background-color: rgba(255,255,255, 0.1);
    --breadcrumbs-text-color: #878b8f;
    --breadcrumbs-selected-text-color: var(--body-text-color);
    --config-param-number-text-color: var(--body-text-color);
    --config-param-number-border-color: #525252;
    --config-param-number-background-color: transparent;
    --config-param-point-background-color: #2d2d2d;
    --config-param-point-text-color: #5e5e5e;
    --config-param-no-value: var(--code-viewer-background);
  }
}
html {
  font-size: 16px;
}

body {
  font-family: Rubik, Roboto, Ubuntu, Arial, sans-serif;
  background: var(--body-background);
  color: var(--body-text-color);
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: none;
}

a {
  color: var(--blue-bright);
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  text-decoration: underline;
}

button {
  font-family: Rubik, Roboto, Ubuntu, Arial, sans-serif;
  outline: none;
  appearance: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  background: none;
  outline: none;
  padding: 0;
  margin: 0;
}

input {
  font-family: Rubik, Roboto, Ubuntu, Arial, sans-serif;
  appearance: none !important;
  outline: none;
  font-size: 14px;
  -webkit-appearance: textfield;
}
input::placeholder {
  opacity: 1;
}
input::-webkit-input-placeholder {
  opacity: 1;
}
input::-webkit-search-cancel-button {
  display: none;
}
input::-webkit-search-results-button {
  display: none;
}
input::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type=search] {
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

h1, h2, h3, h4, h5, h6, strong, b {
  font-weight: 500;
}

.muted {
  color: var(--body-muted-text-color);
}

.toast-container {
  position: fixed;
  width: 100%;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9000;
  pointer-events: none;
}

.toast {
  max-width: 380px;
  font-size: 14px;
  padding: 12px 28px;
  border-radius: 6px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  white-space: nowrap;
  opacity: 0;
  background: #000;
  color: #FFF;
  border-radius: 4px;
  transition: all 0.3s ease-out;
  line-height: 1.4;
  transform: translateY(-100%);
}
.toast.visible {
  transition: opacity 0.2s ease-out;
  opacity: 1;
  transform: none;
}

.content {
  padding: 0 8px 24px 8px;
  max-width: 1200px;
  margin: auto;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--card-background);
  background-clip: border-box;
  border: 1px solid var(--card-border-color);
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1.2rem var(--card-box-shadow-color);
  font-size: 14px;
}
.card + .card {
  margin-top: 18px;
}

.card-title {
  padding: 16px;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  border-radius: 0.5rem 0.5rem 0 0;
  border-bottom: 1px solid var(--card-row-separator);
  background: var(--card-header-background);
  color: var(--card-header-color);
}
.card-title__link {
  color: inherit;
  font-size: 13px;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 6px;
  margin: -12px 4px;
  text-transform: none;
  font-weight: normal;
  opacity: 0.65;
  transition: 0.12s all ease;
}
.card-title__link:hover {
  opacity: 1;
  color: var(--blue-bright);
  text-decoration: none;
}
.card-title__icon {
  fill: currentColor;
  width: 24px;
  height: 24px;
  margin: -4px 8px -4px 0;
}

@media screen and (max-width: 480px) {
  .card + .card {
    margin-top: 12px;
  }
}
.card-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 16px;
  padding: 12px 0;
  border-bottom: 1px solid var(--card-row-separator);
  overflow: hidden;
  word-wrap: anywhere;
  line-height: 1.5;
}
.card-row__name {
  flex-shrink: 0;
  padding-right: 2ex;
  white-space: nowrap;
  width: 100px;
  font-weight: 500;
}
.card-row--wide .card-row__name {
  width: 200px;
}
.card-row__value {
  min-width: 100px;
  max-width: 100%;
}
.card-row__value--flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -8px 0;
}
.card-row:last-child {
  border-bottom: none;
}

.card-row__value > .card-row {
  margin: 0;
  border: none;
  padding: 8px 24px 8px 0;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1.4;
  overflow: visible;
}
.card-row__value > .card-row .card-row__name {
  font-weight: normal;
  opacity: 0.45;
  font-size: 0.9em;
  padding: 0 0 4px 0;
}
.card-row__value > .card-row .skeleton {
  margin-left: -1px;
}

@media screen and (max-width: 480px) {
  .card-row {
    flex-direction: column;
    align-items: flex-start;
  }
  .card-row__name {
    padding-bottom: 5px;
  }
}
.card-main-address {
  overflow-wrap: anywhere;
  word-break: break-all;
}

.card-main-address-badge {
  display: inline-flex;
  font-size: 11px;
  border: 1px solid currentColor;
  padding: 2px 4px;
  border-radius: 3px;
  margin: -10px 3px -10px 0;
  position: relative;
  top: -1px;
  box-sizing: border-box;
  letter-spacing: 0.4px;
  user-select: none;
}
.card-main-address-badge--scam {
  color: var(--badge-red-color);
}

.card-row-wallet-activity {
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  color: #888;
}
.card-row-wallet-activity::before {
  content: "";
  margin-right: 5px;
  display: block;
  width: 8px;
  height: 8px;
  background: currentColor;
  border-radius: 50%;
  font-size: 0;
}
.card-row-wallet-activity--active {
  color: var(--green-bright);
}
.card-row-wallet-activity--frozen {
  color: var(--cyan-bright);
}
.card-row-wallet-activity--suspended {
  color: var(--orange-bright);
}

.tx-history-empty-panel {
  padding: 24px 0;
  text-align: center;
  font-weight: 500;
}

.tx-history-wrap {
  overflow-x: auto;
  scrollbar-width: none;
  width: 100%;
}

.tx-table-cell-icon {
  display: inline-flex;
  padding: 4px;
  box-sizing: border-box;
  background: var(--body-light-muted-color);
  color: var(--tx-table-icon-color);
  border-radius: 4px;
  cursor: pointer;
  margin: 0 0 0 12px;
}
.tx-table-cell-icon--error {
  color: var(--tx-table-icon-error-color);
  background: var(--tx-table-icon-error-background);
}
.tx-table-cell-icon--error:hover {
  color: var(--tx-table-icon-error-hover-color) !important;
}
.tx-table-cell-icon svg {
  width: 16px;
  height: 16px;
}
.tx-table-cell-icon:hover {
  color: var(--body-text-color);
}

@media screen and (max-width: 600px) {
  .tx-table-cell-icon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
  }
  .tx-table-cell-icon svg {
    width: 16px;
    height: 16px;
  }
}
.tx-amount--in {
  color: var(--badge-green-color);
}
.tx-amount--in::before {
  content: "+ ";
}
.tx-amount--out {
  color: var(--badge-red-color);
}
.tx-amount--out::before {
  content: "- ";
}

.tx-msg-block {
  width: auto;
  max-width: 100%;
  box-sizing: border-box;
  background-color: var(--body-light-muted-color);
  padding: 8px 14px 8px 12px;
  border-radius: 3px 15px 15px 15px;
  white-space: normal;
  word-break: break-all;
}

.tx-table {
  border-collapse: collapse;
  width: 100%;
}
.tx-table thead {
  font-weight: 500;
  color: #6c757e;
  border: none;
}
.card--tabbed .tx-table thead {
  background: var(--tx-table-thead-alt-background);
}
.tx-table td, .tx-table th {
  white-space: nowrap;
  overflow: hidden;
  padding: 10px 0;
  border-top: 1px solid var(--card-border-color);
  font-size: 14px;
  max-width: 250px;
}
.tx-table th {
  text-align: left;
  font-weight: 500;
  border: none;
}
.tx-table tbody.sub-list tr:not(.tx-table-row-details) td {
  border-top: none;
}
.tx-table__cell {
  display: flex;
  align-items: center;
  max-width: 100%;
  padding: 0 18px;
  box-sizing: border-box;
  width: 100%;
}
.tx-table__cell--align-right {
  justify-content: flex-end;
}
.tx-table__cell--align-center {
  justify-content: center;
}
.tx-table__badge {
  display: inline-block;
  vertical-align: middle;
  padding: 6px 0;
  min-width: 46px;
  box-sizing: border-box;
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  border-radius: 4px;
}
.tx-table__badge--in {
  color: var(--badge-green-color);
  background-color: var(--badge-green-background-color);
}
.tx-table__badge--out {
  color: var(--badge-red-color);
  background-color: var(--badge-red-background-color);
}
.tx-table__badge--service {
  color: var(--badge-grey-color);
  background-color: var(--badge-grey-background-color);
}
.tx-table__badge--skeleton {
  color: transparent;
  background-color: var(--body-light-muted-color);
}

.tx-table-operation-icon {
  position: absolute;
  right: 1px;
  width: 14px;
  height: 14px;
}

@media screen and (max-width: 600px) {
  .tx-history-table tbody tr td {
    padding: 18px 0;
  }
  .tx-history-table tbody tr:first-child:not(:last-child):not(.skeleton-row) td {
    padding-bottom: 6px;
  }
  .tx-history-table tbody tr:last-child:not(:first-child):not(.skeleton-row) td {
    padding-top: 6px;
  }
  .tx-history-table tbody.sub-list td {
    padding: 2px 0 18px 0;
  }
  .tx-history-table__cell {
    padding: 0 12px;
  }
  .tx-table-operation-icon {
    position: relative !important;
    margin-right: 2px;
    display: inline-block;
    width: 14px;
    height: 14px;
    transform: translateY(2.5px);
  }
}
.alert {
  padding: 16px;
  border-radius: 5px;
  color: var(--badge-red-color);
  border: 1px solid var(--badge-red-color);
  background-color: var(--badge-red-background-color);
}

.tx-table-inner-container {
  display: flex;
  flex-wrap: wrap;
}

.tx-table-inner {
  display: inline-flex;
  flex-direction: column;
  padding: 12px 20px;
  max-width: 70%;
  word-wrap: break-word;
  white-space: normal;
}
.tx-table-inner__header {
  font-weight: 500;
  font-size: 1.05em;
  margin-bottom: 6px;
  display: block;
}

.tx-table-expand-caret {
  position: relative;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 10px;
  margin: -10px;
}
.tx-table-expand-caret--expanded {
  transform: rotate(180deg);
}

.tx-table-row-details {
  box-shadow: 0 -2px 0 0 var(--card-border-color) inset;
  background: var(--tx-table-details-background-color);
}

.skeleton {
  display: block;
  background: var(--body-light-muted-color);
  border-radius: 5px;
  color: transparent;
  user-select: none;
  pointer-events: none;
  fill: none;
}
.skeleton--inline {
  display: inline-flex;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
}

.tx-table-cell-skeleton {
  display: block;
  width: 75%;
  background: var(--body-light-muted-color);
  border-radius: 6px;
  height: 1em;
  margin-bottom: -1px;
}

.tx-page-messages {
  padding: 0 14px;
}

.tx-page-msg {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  align-items: flex-start;
  border-radius: 6px;
  padding: 12px;
  border: 1px solid var(--card-row-separator);
  background: var(--tx-messages-message-background);
}
.tx-page-msg .card-row__name {
  width: 89px;
}
.tx-page-msg .tx-table__badge {
  width: 100%;
  margin-top: 1px;
}

.tx-page-msg-details table {
  border-collapse: collapse;
}
.tx-page-msg-details table td {
  padding: 6px 10px;
  border: none;
  vertical-align: top;
}
.tx-page-msg-details table td:first-child {
  width: 120px;
  padding-left: 0;
  font-weight: 500;
  white-space: nowrap;
}

@media screen and (max-width: 480px) {
  .tx-page-messages {
    padding: 0 10px;
  }
  .tx-page-msg {
    flex-direction: column;
  }
  .tx-page-msg .tx-table__badge {
    margin-bottom: 6px;
    margin-left: -2px;
  }
}
.block-boring-expand {
  display: inline-flex;
  align-items: center;
  font-weight: normal;
  padding: 6px 10px 6px 14px;
  background: var(--body-light-muted-color);
  cursor: pointer;
  border-radius: 24px;
  font-size: 13px;
}
.block-boring-expand svg {
  margin-left: 6px;
  width: 14px;
  height: 14px;
  margin-bottom: -1px;
}
.block-boring-expand:hover {
  color: var(--blue-bright);
}

.block-empty-jettons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 0;
  border-top: 1px solid var(--card-row-separator);
  overflow: hidden;
  word-wrap: anywhere;
  line-height: 1.5;
}

.block-nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px 0;
}
.block-nav__hcontainer {
  display: flex;
}
.block-nav__hcontainer .block-nav__pill--next {
  margin-left: 14px;
}
.block-nav__vcontainer {
  display: flex;
  flex-direction: column;
}
.block-nav__vcontainer .block-nav__pill {
  margin-bottom: 14px;
}
.block-nav__vcontainer .block-nav__pill:last-child {
  margin-bottom: 0;
}
.block-nav__pill {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background: var(--card-background);
  border: 1px solid var(--card-border-color);
  color: var(--body-text-color);
  text-decoration: none;
  border-radius: 6px;
  font-size: 14px;
  box-shadow: 0 0.3rem 1rem var(--card-box-shadow-color);
  cursor: pointer;
}
.block-nav__pill--prev {
  padding-left: 0;
}
.block-nav__pill--next {
  padding-right: 0;
}
.block-nav__pill--disabled {
  opacity: 0.5;
  pointer-events: none;
}
.block-nav__pill svg {
  width: 16px;
  height: 16px;
  margin: 0 8px;
  fill: currentColor;
}
.block-nav__pill:hover {
  color: var(--blue-bright);
  text-decoration: none;
}

.card-main-qr-button {
  display: inline-flex;
  padding: 6px 12px 6px 4px;
  margin: -6px 0;
  position: relative;
  bottom: -1px;
  color: var(--body-text-color);
  opacity: 0.65;
  cursor: pointer;
}
.card-main-qr-button__icon {
  width: 12px;
  height: 12px;
  fill: currentColor;
}
.card-main-qr-button:hover {
  opacity: 1;
}

.card-container {
  padding: 0 14px;
}

.nft-link {
  display: inline-flex;
  align-items: center;
  color: currentColor;
  opacity: 0.9;
  margin-right: 20px;
  margin-bottom: 6px;
  flex-wrap: nowrap;
  max-width: 90%;
}
.nft-link svg {
  flex-shrink: 0;
  fill: currentColor;
  margin-right: 4px;
  opacity: 0.7;
  height: 1em;
}
.nft-link .address-link {
  max-width: 150px;
  color: currentColor;
  line-height: 1.2;
}

.nft-collection-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 32px;
}

@media all and (min-width: 640px) {
  .nft-collection-card {
    background: none;
    border: none;
    box-shadow: none;
  }
}
@media all and (max-width: 640px) {
  .nft-collection-card {
    margin-top: 0;
    flex-direction: column;
  }
}
.nft-collection-cover-container {
  flex-grow: 1;
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
}

@media all and (max-width: 640px) {
  .nft-collection-cover-container {
    width: 100%;
  }
}
.nft-collection-cover {
  display: block;
  aspect-ratio: 1/1;
  object-fit: cover;
  background-color: var(--card-row-separator);
  width: 120px;
  height: 120px;
  border-radius: 12px;
  background-size: cover;
  background-position: center;
}

@media all and (max-width: 640px) {
  .nft-collection-cover {
    object-fit: contain;
    min-width: 100%;
    height: auto;
    max-height: 240px;
    border-radius: 6px 6px 0 0;
  }
}
.nft-collection-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  box-sizing: border-box;
  padding-left: 18px;
  padding-top: 6px;
}

.nft-collection-title {
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 12px;
  margin-top: 0;
}

.nft-collection-description {
  font-size: 17px;
  margin-bottom: 20px;
  line-height: 1.4;
}

.nft-collection-meta {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  line-height: 2;
  margin-top: -4px;
}

@media all and (max-width: 640px) {
  .nft-collection-info {
    padding: 18px 14px 10px 14px;
  }
  .nft-collection-title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .nft-collection-description {
    font-size: 14px;
    line-height: 1.4;
    opacity: 0.9;
  }
  .nft-collection-meta {
    flex-direction: column;
    align-items: flex-start;
  }
}
.nft-items-container {
  margin: 32px 0 0 0;
  display: grid;
  grid-column-gap: 28px;
  grid-row-gap: 24px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: 1fr;
}
.nft-items-container__loader {
  margin-top: 18px;
}

.nft-preview {
  display: flex;
  flex-direction: column;
  background-color: var(--card-background);
  background-clip: border-box;
  border-radius: 0.5rem;
  font-size: 14px;
  color: var(--body-text-color);
  overflow: hidden;
  box-shadow: 0 0.5rem 1.2rem var(--card-box-shadow-color), 0 0 0 1px var(--card-border-color) inset;
}
.nft-preview--loading {
  pointer-events: none;
}
.nft-preview:hover {
  text-decoration: none;
}
.nft-preview:hover .nft-preview__title {
  text-decoration: underline;
}
.nft-preview__image {
  flex-shrink: 0;
  aspect-ratio: 1/1;
  width: 100%;
  object-fit: cover;
  border-radius: 0.5rem 0.5rem 0 0;
  overflow: hidden;
  display: block;
  background-color: var(--ambilight-overlay-color);
  background-size: cover;
  background-position: center;
  background-blend-mode: soft-light;
}
.nft-preview__title {
  padding: 12px 12px 0 12px;
  font-weight: 500;
  font-size: 16px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}
.nft-preview__meta {
  font-size: 13px;
  padding: 5px 12px 10px 12px;
  font-weight: 400;
  opacity: 0.8;
  margin-top: auto;
}

@media all and (max-width: 900px) {
  .nft-items-container {
    margin-top: 18px;
    grid-column-gap: 22px;
    grid-row-gap: 20px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media all and (max-width: 480px) {
  .nft-items-container {
    grid-column-gap: 16px;
    grid-row-gap: 14px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.show-more-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: linear-gradient(0deg, var(--card-background) 0%, var(--card-background) 10%, transparent 100%);
  border-radius: 0 0 0.5rem 0.5rem;
  backdrop-filter: blur(1px);
}
.show-more-container button {
  background-color: transparent;
  color: var(--blue-bright);
  text-shadow: 0 2px 1px var(--card-background), 2px 0 1px var(--card-background), -2px 0 1px var(--card-background), 0 -2px 1px var(--card-background);
  font-weight: 500;
  padding: 10px 14px 12px 18px;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
}
.show-more-container button:hover {
  text-decoration: underline;
}
.show-more-container--config {
  position: absolute;
  left: 0;
}

.mugen-scroll {
  display: flex;
}
.mugen-scroll__button {
  text-transform: uppercase;
  cursor: pointer;
  margin: 12px auto;
  text-align: center;
  padding: 10px 22px;
  font-weight: 500;
  background: var(--blue-bright-background);
  color: var(--blue-bright);
  border-radius: 4px;
  font-size: 13px;
}
.mugen-scroll__button:hover {
  background: rgba(56, 139, 253, 0.2);
}

.bright-blue-button {
  background: var(--big-blue-button-background);
  color: #FFF;
  font-weight: 500;
  padding: 10px 0;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 6px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  transition: 0.15s background ease;
  text-decoration: none;
}
.bright-blue-button:hover {
  text-decoration: none;
  background: var(--big-blue-button-hover-background);
}
.bright-blue-button--disabled {
  background: var(--big-blue-button-disabled-background);
  color: var(--big-blue-button-disabled-color);
  pointer-events: none;
}

.modal-banner {
  display: flex;
  align-items: flex-start;
  padding: 12px;
  margin: 12px 12px 8px 12px;
  background: var(--modal-banner-background);
  border-radius: 16px;
}
.modal-banner__text {
  line-height: 1.5;
  color: var(--modal-banner-text-color);
}

.modal-banner-icon {
  margin-right: 12px;
  padding: 10px;
  background: var(--modal-banner-round-background);
  border-radius: 50%;
  font-size: 0;
}
.modal-banner-icon__svg {
  fill: var(--modal-banner-round-icon-fill);
  position: relative;
  width: 24px;
  height: 24px;
}

.new-row-animation {
  animation-name: blink-animation;
  animation-duration: 1s;
}

@keyframes blink-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}